import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56f617cd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "project-table",
  style: {
    "margin-top": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $data.tableData,
    "row-key": "id",
    border: "",
    "header-cell-style": {
      'text-align': 'center',
      'color': '#999999',
      'padding': '16px 0',
      'font-size': '16px',
      'background-color': '#F6F6F6'
    },
    "cell-style": {
      'text-align': 'center',
      'padding': '16px 0'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "id",
      label: "编号"
    }), _createVNode(_component_el_table_column, {
      prop: "name",
      label: "环节名称"
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "评价状态"
    }, {
      default: _withCtx(scope => [_createElementVNode("div", {
        class: _normalizeClass(scope.row.evaRecord ? scope.row.evaRecord.check_state == 1 ? 'success' : '' : '')
      }, _toDisplayString(scope.row.evaRecord ? scope.row.evaRecord.check_state == 1 ? '已评价' : '未评价...' : '未评价'), 3)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "评价教师"
    }, {
      default: _withCtx(scope => [_createElementVNode("div", null, _toDisplayString(scope.row.evaRecord ? scope.row.evaRecord.teacher ? scope.row.evaRecord.teacher.name : '' : ''), 1)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "评价开始时间"
    }, {
      default: _withCtx(scope => [_createElementVNode("div", null, _toDisplayString(scope.row.evaRecord ? scope.row.evaRecord.create_time : ''), 1)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "评价完成时间"
    }, {
      default: _withCtx(scope => [_createElementVNode("div", null, _toDisplayString(scope.row.evaRecord ? scope.row.evaRecord.eva_time : ''), 1)]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])])])]);
}