import { getActiveCourseLinkEva } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      pageInfo: {
        course_id: '',
        operator_server_active_id: '',
        check_state: ''
      },
      dialogFormVisible: false
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.pageInfo = data;
    this.getList();
  },
  methods: {
    getList() {
      getActiveCourseLinkEva(this.pageInfo).then(res => {
        this.tableData = res.data;
      });
    }
  }
};